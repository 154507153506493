import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Autoplay, Navigation, Pagination } from 'swiper/modules';
// import ShareBox from '../../hotissue/box/ShareBox';

export default function   mnews_Box(props) {
  const [swiperRef, setSwiperRef] = useState(null);
  let [maxPageCount, setMaxPageCount] = useState(0);
  let [naviCount, setNaviCount] = useState(1);

  let [width, setWidth] = useState(282);
  let [height, setHeight] = useState(159);

  const handleImageLoad = (e) => {
    const nHeight = e.naturalHeight;
    const nWidth = e.naturalWidth;
    const scale = e.naturalWidth / 282;
    const scaleHeight = nHeight / scale;

    setHeight(scaleHeight);
    if (props.forceUpdate) {
      props.forceUpdate();
    }
  };

  useEffect(() => {
    if (swiperRef != null) {
      swiperRef.slideTo(0, 0); //TAB 영역 누르면 스와이퍼 초기화
    }

    setMaxPageCount(Math.ceil(props.data.contents.length / 4));
  }, []);

  useEffect(() => {
    //alert('www');
    window.__SBSLogModuleDataLayer = window.__SBSLogModuleDataLayer || [];
  }, []);
  //link로그를
  const handleClick = (a, b, c, d, e) => {
    let conver_current_s = parseInt(c);
    let conver_current_s2 = parseInt(props.loc_idx);

    //통계에 넙겨줄 값을정리한다,상단여역 pgmbox 프로그램 / 메뉴 / 플레이톡이 있음
    window.__SBSLogModuleDataLayer.push({
      type: 'content',
      option: {
        moduleId: '기사', //모듈 아이디 없으면
        moduleTitle: 'SBS뉴스', //모듈 제목 (Require)
        moduleSubTitle: '',
        moduleActive: 'C', //콘텐츠 선택 정보 Click: C, Hover: H
        moduleSeq: conver_current_s2, //페이지에서 해당 모듈의 위치
        moduleItemSeq: conver_current_s, //모듈 내에서 콘텐츠의 위치
        programId: '', //프로그램의 아이디 (추가)
        programTitle: d, //프로그램의 아이디 (추가)
        contentId: '', //식별자
        contentTitle: b.title, //콘텐츠의 제목  (Require)
        contentNumber: '',
        contentType: 'AR', //콘텐츠의 타입 (별도 표 참조)
      },
    });
  };

  const handleClick_custom = (a, b, c, d) => {
    window.__SBSLogModuleDataLayer.push({
      type: 'click',
      option: {
        clickName: '뉴스 전체보기',
        clickType: 'NC',
        value1: '',
        value2: '',
        value3: '',
        value4: '',
        value5: '',
      },
    });
  };

  return (
    <>
      <div className="mainContents-typeWhite">
        {/* <!-- 스브스프리미엄 --> */}
        <div className="contentsBlock_w">
          <div className="moduleTitleline_w">
            <h2 className="moduleTitleline-Title">{props.data.list_title}</h2>
            <a
              href={props.data.alllink_url}
              onClick={(e) => handleClick_custom(e, '', '0', 'all')}
              target="_blank"
              className="moduleTitleline-linkMore"
              style={{
                visibility:
                  props.data.alllink_yn == 'Y' &&
                  props.data.alllink_url != '' &&
                  props.data.alllink_url != null
                    ? 'visible'
                    : 'hidden',
              }}
            >
              {props.data.alllink_title}
            </a>
          </div>
          <div className={'listModule_w_news news' + props.loc_idx}>
            <button
              type="button"
              aria-label="다음 콘텐츠 보기"
              className="btnTypeList-Next swiper-button-next"
              onClick={() => setNaviCount(naviCount + 1)}
            >
              <span className="iconSvg_arrow">
                <i className="hide">다음</i>
              </span>
              <span className="pagingNumber_w">
                <strong className="pagingNumber-now">{naviCount}</strong>/
                {maxPageCount}
              </span>
            </button>
            <Swiper
              onSwiper={setSwiperRef}
              slidesPerView={4}
              spaceBetween={24}
              slidesPerGroup={4}
              loop={false}
              pagination={{
                // 페이징 설정
                el: `.news${props.loc_idx} .swiper-pagination`,
                clickable: false, // 페이징을 클릭하면 해당 영역으로 이동, 필요시 지정해 줘야 기능 작동
              }}
              navigation={{
                nextEl: `.news${props.loc_idx} .swiper-button-next`,
                prevEl: `.news${props.loc_idx} .swiper-button-prev`,
              }}
              modules={[Autoplay, Pagination, Navigation]}
              className="listModuleSlider"
              wrapperClass="SliderContent"
            >
              {props.data.contents.map((item, index) => {
                const handleOpenPopup = () => {
                  //  485 * 928
                  const popup = window.open(
                    item.talk_link,
                    '타이틀명을 입력하시오',
                    'top=0, left=0, width=485, height=928, status=no, menubar=no, toolbar=no, resizable=no'
                  );
                };
                return (
                  <SwiperSlide key={index}>
                    <div className="listModuleItem swiper-slide">
                      <div className="moduleBoxWrap_news">
                        <Link href={item.link_url}>
                          <a
                            onClick={(e) =>
                              handleClick(
                                e,
                                item,
                                index,
                                item.sub_title,
                                '인기'
                              )
                            }
                            target={item.link_new_yn == 'Y' ? '_blank' : ''}
                            className="mb_link"
                          >
                            <div className="mb_image_w">
                              <div className="mb_image_inner">
                                {/* <img src={item.img_url}   className="mp_image" alt={item.img_attr} /> */}
                                {/* <img src="https://via.placeholder.com/282x159" className="mb_image" alt="웹접근성을 위해 이미지에 맞는 텍스트를 제공해주세요." /> */}
                                {/* <Image
                                                                    onLoadingComplete={(e) => handleImageLoad(e)}
                                                                    src={item.img_url}
                                                                    className="mb_image"
                                                                    layout='fixed'
                                                                    width={282}
                                                                    height={height}
                                                                    quality={props.data.imageQuality || 75}
                                                                    alt={item.img_attr}
                                                                /> */}
                                <Image
                                  className="mb_image"
                                  src={item.img_url}
                                  alt={item.img_attr}
                                  layout="fill"
                                  style={{ width: '100%', height: '100%' }}
                                  quality={props.imageQuality || 75}
                                />
                              </div>
                            </div>
                            <div className="mb_info_w">
                              <span className="mbif_title">{item.title}</span>
                              <span className="mbif_subinfo">
                                {item.sub_title}
                              </span>
                            </div>
                          </a>
                        </Link>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <div className="swiper-pagination"></div>
            <button
              type="button"
              aria-label="이전 콘텐츠 보기"
              className="btnTypeList-Prev swiper-button-prev"
              onClick={() => setNaviCount(naviCount - 1)}
            >
              <span className="iconSvg_arrow">
                <i className="hide">이전</i>
              </span>
              <span className="pagingNumber_w">
                <strong className="pagingNumber-now">{naviCount}</strong>/
                {maxPageCount}
              </span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
